




import { Component, Vue } from 'vue-property-decorator';
import Home from '@/app/components/Home.vue';

@Component({
  components: {
    Home,
  },
})
export default class HomeView extends Vue {
}
